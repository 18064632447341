.gj9EtG_-resizingSlideContainer {
  max-width: 100%;
  max-height: 100%;
}

.gj9EtG_-meta {
  background-color: ivory;
}

.gj9EtG_-label {
  margin: 0;
  padding: .25em;
}

.gj9EtG_container {
  grid-template-rows: minmax(25%, 60%) minmax(min-content, min-content) minmax(25%, 1fr) minmax(min-content, min-content);
  grid-template-columns: repeat(4, minmax(0, 25%));
  grid-template-areas: "gj9EtG_curr gj9EtG_curr gj9EtG_curr gj9EtG_curr"
                       "gj9EtG_lbll gj9EtG_lbll gj9EtG_lblr gj9EtG_lblr"
                       "gj9EtG_note gj9EtG_note gj9EtG_next gj9EtG_next"
                       "gj9EtG_note gj9EtG_note gj9EtG_time gj9EtG_time";
  display: grid;
}

.gj9EtG_slideCurrent {
  grid-area: gj9EtG_curr;
}

.gj9EtG_labelLeft {
  grid-area: gj9EtG_lbll;
}

.gj9EtG_labelRight {
  text-align: right;
  grid-area: gj9EtG_lblr;
}

.gj9EtG_notes {
  grid-area: gj9EtG_note;
}

.gj9EtG_slideNext {
  grid-area: gj9EtG_next;
}

.gj9EtG_timer {
  text-align: center;
  grid-area: gj9EtG_time;
  padding: .25em;
}

._7OtJqa_hidden {
  display: none;
}

.pj8KFq_container {
  break-after: page;
  break-inside: avoid-page;
  background-color: #fff;
}

.pj8KFq_notes {
  border-top: 1px solid #000;
}

.cCiXQq_container {
  background-color: #646464;
}

@media screen {
  .cCiXQq_container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.IZYkyq_-inner {
  width: var(--slide-target-width);
  height: var(--slide-target-height);
  transform-origin: 0 0;
}

.IZYkyq_inner4x3 {
  --slide-target-width: 1600px;
  --slide-target-height: 1200px;
}

.IZYkyq_inner16x9 {
  --slide-target-width: 1600px;
  --slide-target-height: 900px;
}

@media print {
  .IZYkyq_outer:has(.IZYkyq_inner16x9) {
    width: 800px;
    height: 450px;
  }

  .IZYkyq_outer:has(.IZYkyq_inner4x3) {
    width: 800px;
    height: 600px;
  }

  .IZYkyq_-inner {
    scale: .5;
  }
}

.aBfWRG_container {
  background-color: #fff;
  padding: 2rem 4rem;
  font-size: 1.5rem;
  position: relative;

  & code[class*="language-"], & pre[class*="language-"] {
    font-size: 1.15rem;
    line-height: 1.2;
  }

  & h1 {
    margin: 0 0 2rem;

    & code {
      margin: 0;
      line-height: 1;
    }
  }

  & ul, & ol {
    font-size: 1.75rem;
  }

  & li {
    margin: 1.5rem 0;
  }
}

.aBfWRG_pageNumber {
  color: #a0a0a0;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

@media print {
  .aBfWRG_pageNumber {
    display: none;
  }
}

.ySmK_a_container {
  object-fit: contain;
}

.hQx4Ja_container {
  text-align: center;
  background-color: #fff;
  place-content: center;
  font-size: 3rem;
  display: grid;
}

.tolyLa_hovered {
  position: relative;
}

.tolyLa_copy {
  display: none;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.tolyLa_hovered:hover .tolyLa_copy {
  display: block;
}

.D9r7qa_container {
  background: #eee8aa;
  margin: 0;
  padding: 1em;
}

.D9r7qa_quote {
  margin: 0;
  font-size: 2.5rem;
}

.D9r7qa_caption {
  text-align: right;
  font-style: italic;

  &:before {
    content: "— ";
  }
}

.e4Rufq_-center {
  grid-template-rows: minmax(0, auto);
  grid-template-columns: minmax(0, auto);
  place-items: center;
  display: grid;
}

.e4Rufq_-expand {
  width: 100%;
  height: 100%;
}

.e4Rufq_-fullImage {
  max-width: calc(var(--slide-target-width) * .9);
  max-height: calc(var(--slide-target-height) * .9);
  object-fit: contain;
  padding: 0 2em;
}

.e4Rufq_-chunkyImage {
  margin: 0 auto;
  display: block;
}

.K4NMUa_dialog {
  &::backdrop {
    background: #00000080;
  }
}

.K4NMUa_container {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.K4NMUa_title {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
}

.K4NMUa_close {
  aspect-ratio: 1;
  border-width: 0 0 1px 1px;
  position: absolute;
  top: 0;
  right: 0;
}

.K4NMUa_sets {
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.K4NMUa_set {
  justify-content: space-between;
  align-items: center;
  gap: 5em;
  display: flex;
}

.K4NMUa_keycaps {
  gap: .25em;
  display: flex;
}

.K4NMUa_keycap {
  white-space: nowrap;
  border: 1.5px solid #000;
  padding: 3px 5px;
  line-height: .85em;
  display: inline-block;
  box-shadow: 1.5px 1.5px #00000040;
}
/*# sourceMappingURL=index.21935555.css.map */
